import 
{ 
    isAValidUrn,
    ConsentStatus,
    GetConsentCssClass,
    GetConsentStatus,
    GetAccountType,
    MaskCpfCnpj,
    doSomeWork
} from '@/util/util.js';

const ConsultConsentErrors = {
    InvalidUrn : "Invalid URN",
    InvalidToken: "Invalid token"
};

const HOST = process.env.VUE_APP_CONSENTS;
const SERVICE = `${HOST}/consent/consent-admin`;

function parseStatus(status) {
    return GetConsentCssClass(status);
}

function parseTitle(status) {
    return GetConsentStatus(status);
}

function convertUtcDateToString(date, locale, isDateTime){
    var tmp = new Date(date);
    return isDateTime 
      ? tmp.toLocaleString(locale) 
      : tmp.toLocaleDateString(locale);
}

function parseConsentDate(status, consentBody, locale) {
    console.log('consentBody', consentBody)

    switch(status) {
      case ConsentStatus.ACCEPTED : return `Aprovação: ${this.convertUtcDateToString(consentBody.statusUpdateDateTime, locale, true)}`
      case ConsentStatus.REJECTED : return `Rejeição: ${this.convertUtcDateToString(consentBody.statusUpdateDateTime, locale, true)}`;
      case ConsentStatus.WAITING  : return `Limite para aprovação: ${this.convertUtcDateToString(consentBody.expirationDateTime, locale, true)}`;
      default                     : return this.convertUtcDateToString(consentBody.statusUpdateDateTime, locale, true);
    }
}
function parseCurrency(locale, type, value) {

    var formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: type,
      minimumFractionDigits: 2,
      maximumFractionDigits: 4,
    });

    return formatter.format(value); 
}

function parseDebtorBranch(branchCode) {
    return `Agência: ${branchCode}`;
}
function parseDebtorAccount(accountType, accountNumber) {
    return `${GetAccountType(accountType)}: ${accountNumber}`;
}

function maskCpfCnpj(cpfCnpj, size) {
    return size == 11 
      ? `CPF ${MaskCpfCnpj(cpfCnpj, size)}`
      : `CNPJ ${MaskCpfCnpj(cpfCnpj, size)}`;
}
 
async function getConsentById(idConsent, origin, token) {

    let timeoutConsult = sessionStorage.getItem("MANAGER_TECBAN_TIMEOUT_C");
    let timeoutDetail = sessionStorage.getItem("MANAGER_TECBAN_TIMEOUT_D");
    

    if(!isAValidUrn(idConsent))
        return { getConsentError: ConsultConsentErrors.InvalidUrn };

    if(token === "invalid") {
        return { getConsentError: ConsultConsentErrors.InvalidToken};
    }

    if(timeoutConsult === "true")
        timeoutDetail = false;
    if(timeoutDetail === "true")
        timeoutConsult = false;

    var url = `${SERVICE}/${idConsent}`;

    var requestOptions = {
        method: 'GET',
        headers: {
            'Authorization':'Bearer '+token
          }
        
    };

    //console.log(requestOptions);
    //console.log(token);
    //console.log(`Fetching ${url}...`);

    if(timeoutConsult === "true" && origin == "consult") {

        //console.log("working...");
        await new Promise(resolve => setTimeout(resolve, 30000));
        //console.log("work finished!");

        sessionStorage.removeItem("MANAGER_TECBAN_TIMEOUT_C");

    } 
    else if(timeoutDetail === "true" && origin == "detail") {

        //console.log("working...");
        await new Promise(resolve => setTimeout(resolve, 30000));
        //console.log("work finished!");

        sessionStorage.removeItem("MANAGER_TECBAN_TIMEOUT_D");
    }

    try{
        var response = await fetch(url,requestOptions);
        return await response.json();
    }
    catch(error){
        console.log(error);
        return error;
    }
}

export 
{ 
    getConsentById,
    ConsultConsentErrors
};