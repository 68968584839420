<template>

    <div class="p-d-flex p-flex-column container">
        <div class="p-d-flex p-jc-center">
            <div class="p-d-flex p-flex-column box" style="margin: 5em; margin-top: 186px !important;">
                <div class="p-mb-2">
                    <div class="p-mt-3">
                        <div class="p-d-flex p-jc-center">
                            <div class="box-text"> Resumo do Consentimento<i style="margin-left: 5px" :class="loadingIcon"></i></div>
                        </div>
                    </div>
                </div>

                <div class="p-d-flex p-jc-center p-mb-2">
                    <div class="p-mt-3" v-show="showErrMessage">
                        <MessagesManager :msg="errMessage"/>    
                    </div>
                </div>

                <div class="p-mt-5 p-mb-5" :style="detailConsentStyle">
                    <div class="p-grid">
                        <div class="p-col-4 stripped text">
                            <div><strong>ID:</strong> {{consentId}} </div>
                        </div>
                        <div class="p-col-4 stripped text">
                            <div><strong>Status:</strong> <span :class="getStatusClass(status)"> {{getStatusName(status)}} </span> </div>
                        </div>
                        <div class="p-col-4 stripped text">
                            <div><strong>Iniciadora de Pagamento:</strong> {{initiatorName}} </div>
                        </div>

                        <div class="p-col-4 text" v-show="status != 'AWAITING_AUTHORISATION'">
                            <div><strong>{{getConsentDateMessage(status)}}</strong> {{approvedDate}} </div>
                        </div>
                        <div class="p-col-4 text" v-show="approvedBy != null && approvedBy != undefined && approvedBy != '' && status != 'AWAITING_AUTHORISATION'">
                            <div><strong>{{getConsentAprrovedByMessage(status)}}</strong> {{approvedBy}} </div>
                        </div>
                        <div class="p-col-4 text" v-show="status != 'AWAITING_AUTHORISATION'">
                            <div></div>
                        </div>

                        <div class="p-col-12 text">
                            <div><strong>Dados do Pagador</strong></div>
                        </div>

                        <div class="p-col-4 stripped text">
                            <div><strong>CPF/CNPJ:</strong> {{payerCpfCnpj}} </div>
                        </div>
                        <div class="p-col-4 stripped text">
                            <div v-if="payerName != null && payerName != undefined && payerName != ''">
                               <strong>Nome:</strong> {{payerName}}
                            </div>
                        </div>
                        <div class="p-col-4 stripped text">
                            <div></div>
                        </div>

                        <template v-if="payerAccType != null && payerBranch != null && payerAccount != null && 
                                        payerAccType != undefined && payerBranch != undefined && payerAccount != undefined && 
                                        payerAccType != '' && payerBranch != '' && payerAccount != ''">

                            <div class="p-col-4 text">
                                <div> <strong>Tipo Conta:</strong> {{payerAccType}}</div>
                            </div>
                            <div class="p-col-4 text">
                                <div><strong>Agência:</strong> {{payerBranch}}</div>
                            </div>
                            <div class="p-col-4 text">
                                <div><strong>Conta:</strong> {{payerAccount}}</div>
                            </div>

                        </template>

                        <div class="p-col-12 text">
                            <div><strong>Dados do Beneficiário</strong></div>
                        </div>

                        <div class="p-col-4 stripped text">
                            <div><strong>Nome/Razão Social:</strong> {{creditorName}} </div>
                        </div>
                        <div class="p-col-4 stripped text">
                            <div><strong>CPF/CNPJ:</strong> {{creditorCpfCnpj}} </div>
                        </div>
                        <div class="p-col-4 stripped text">
                            <div></div>
                        </div>

                        <template v-if="localInstrument == 'MANU'">
                            <div class="p-col-4 stripped text">
                                <div><strong>Banco:</strong> {{creditorBankCode}} </div>
                            </div>
                            <div class="p-col-4 stripped text">
                                <div><strong>Tipo de Conta:</strong> {{creditorAccountType}} </div>
                            </div>
                            <div class="p-col-4 stripped text">
                                <div><strong></strong></div>
                            </div>

                            <div class="p-col-4 stripped text">
                                <div><strong>Agência:</strong> {{creditorAgg}} </div>
                            </div>
                            <div class="p-col-4 stripped text">
                                <div><strong>Conta:</strong> {{creditorAccount}} </div>
                            </div>
                            <div class="p-col-4 stripped text">
                                <div><strong></strong></div>
                            </div>
                        </template>

                        <div class="p-col-4 text">
                            <div><strong>Data de Iniciação de Pagamento:</strong> {{paymentIniciationDate}} </div>
                        </div>
                        <div class="p-col-4 text">
                            <div><strong>Valor:</strong> {{paymentValue}} </div>
                        </div>
                        <div class="p-col-4 text">
                            <div></div>
                        </div>
                        <div v-if="paymentDateSchedule">
                            <div class="p-col-4 text">
                                <div><strong>Data do Agendamento:</strong> {{paymentDateSchedule}} </div>
                            </div>
                            <div class="p-col-8 text">
                                <div></div>
                            </div>
                        </div>

                        <div class="p-col-4 text">
                            <div><strong>Forma de Pagamento:</strong> {{paymentType}} </div>
                        </div>

                        <template v-if="localInstrument == 'QRES'">
                            <div class="p-col-4 text">
                                <div><strong>QR code estático</strong></div>
                            </div>
                            <div class="p-col-4 text">
                                <div><strong>Chave PIX:</strong> {{pixKey}} </div>
                            </div>
                        </template>

                        <template v-if="localInstrument == 'QRDN'">
                            <div class="p-col-4 text">
                                <div><strong>QR code dinâmico</strong></div>
                            </div>
                            <div class="p-col-4 text">
                                <div><strong>Chave PIX:</strong> {{pixKey}} </div>
                            </div>
                        </template>

                        <template v-if="localInstrument == 'DICT' || localInstrument == 'INIC'">
                            <div class="p-col-4 text">
                                <div><strong>Chave PIX:</strong> {{pixKey}} </div>
                            </div>
                            <div class="p-col-4 text">
                                <div><strong></strong></div>
                            </div>
                        </template>

                        <template v-if="localInstrument != 'QRES' && localInstrument != 'QRDN' && localInstrument != 'MANU' && localInstrument != 'DICT' && localInstrument != 'INIC'">
                            <div class="p-col-4 text">
                                <div><strong></strong></div>
                            </div>
                            <div class="p-col-4 text">
                                <div><strong></strong></div>
                            </div>
                        </template>

                        <div v-if="bank_fee != null && bank_fee != undefined && bank_fee != ''" class="p-col-4 text">
                            <div><strong>Tarifa Bancária:</strong> {{bank_fee}} </div>
                        </div>
                        
                    </div>
                </div>

                <div class="p-mb-2 p-d-flex p-jc-center">
                    <div class="p-mt-5 p-mb-5">
                        <a @click="goBack()"><u>Voltar</u></a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
Date.prototype.addHours = function(h){
  this.setHours(this.getHours()+h);
  return this;
};
import { FetchStates, GetConsentCssClass, ConsentStatus, MaskCpfCnpj, getWithExpiry } from '@/util/util.js';
import { getConsentById, ConsultConsentErrors } from '@/services/consult-consent-service';

const TECBAN_TIMEOUT = 30000;
const MAX_TIMEOUT    = 300000;
const ONLINE_CHECK = 3000;

export default {

    data(){
        return {
            showDetail: false,
            showErrMessage: false,
            errMessage: "",
            consentId: "",
            status: "",
            initiatorName: "",
            approvedDate: "",
            approvedBy: "",
            payerName: "",
            payerCpfCnpj: "",
            payerAccType: "",
            payerBranch: "",
            payerAccount: "",
            localInstrument : "",
            creditorName: "",
            creditorCpfCnpj: "",
            creditorBankCode: "",
            creditorAgg: "",
            creditorAccount: "",
            creditorAccountType: "",
            paymentIniciationDate: "",
            paymentDateSchedule: "",
            paymentValue: "",
            paymentType: "",
            pixKey: "",
            bank_fee: "",
            loadingIcon: "",
            detailConsentStyle: "font-size:14px; visibility:hidden",
            accountTypes: {
                'CACC' : 'Conta corrente',
                'SLRY' : 'Conta salário',
                'SVGS' : 'Conta poupança',
                'TRAN' : 'Conta de pagamento pré-paga'
            }
        }
    },
    methods:  {
        goBack(){
            this.$router.go(-1);
        },
        getStatusClass(status) {
            return `status status-${GetConsentCssClass(status)}`;
        },
        getStatusName(status) {
            switch(status) {
                case ConsentStatus.WAITING : return "Aguardando autorização";
                case ConsentStatus.ACCEPTED: return "Autorizado";
                case ConsentStatus.REJECTED: return "Rejeitado";
                case ConsentStatus.CONSUMED: return "Consumido";
            }
        },
        getConsentDateMessage(status) {
            switch(status) {
              case ConsentStatus.ACCEPTED : return `Data de aprovação: `
              case ConsentStatus.CONSUMED : return `Data de aprovação: `
              case ConsentStatus.REJECTED:  return `Data da Rejeição: `;
              default: return "";
            }
        },
        getConsentAprrovedByMessage(status) {
            switch(status) {
              case ConsentStatus.ACCEPTED : return `Autorizado por: `
              case ConsentStatus.CONSUMED : return `Autorizado por: `
              case ConsentStatus.REJECTED:  return `Rejeitado por: `;
              default: return "";
            }
        },
        convertUtcDateToString(date, locale, isDateTime){
            const tmp = new Date(date)
            const newDate = new Date(date).addHours(3)
            return isDateTime ? tmp.toLocaleString(locale) : newDate.toLocaleDateString(locale)
        },
        maskCpfCnpj(cpfCnpj, size) {
          return size == 11 
            ? `${MaskCpfCnpj(cpfCnpj, size)}`
            : `${MaskCpfCnpj(cpfCnpj, size)}`;
        },
        parseCurrency(locale, type, value) {
          var formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: type,
            minimumFractionDigits: 2,
            maximumFractionDigits: 4,
          });

          return formatter.format(value); 
        },
        checkOnline(){
            if(!navigator.onLine){
                this.errMessage = "Houve falha na comunicação.Tente novamente mais a tarde.";
                this.showErrMessage = true;
                this.loadingIcon = "";
            }
        },
        isEmpty(obj){
            if (obj === 'undefined' || obj == null)
                return true;
            
            if (typeof obj === 'string')
                return obj.replace(/\s/g, '').length < 1;

            return false;
        }
    },

    async created() {
        var maxTimeout;
        let timeout;
        let token = getWithExpiry('token');

        this.loadingIcon = "pi pi-spin pi-spinner";

        setInterval(() => {
            this.checkOnline();
        }, 3000);

        maxTimeout = setTimeout(() => { 
            sessionStorage.removeItem("MANAGER_TECBAN_TOKEN"); 
            this.errMessage = "Você atingiu o limite de 05 minutos sem executar nenhuma ação.";
            this.showErrMessage = true;
            this.searchDisabled = true;
            this.loadingIcon = "";
        }, MAX_TIMEOUT);

        maxTimeout = setTimeout(() => { 
            sessionStorage.removeItem("MANAGER_TECBAN_TOKEN"); 
            sessionStorage.removeItem("ACTUAL_CONSENT_ID"); 
            this.errMessage = "Você atingiu o limite de 05 minutos sem executar nenhuma ação.";
            this.showErrMessage = true;
            this.searchDisabled = true;
            this.loadingIcon = "";
        }, MAX_TIMEOUT);

        document.addEventListener('click', () => {  
            clearTimeout(maxTimeout);
            maxTimeout = setTimeout(() => { 
                sessionStorage.removeItem("MANAGER_TECBAN_TOKEN"); 
                sessionStorage.removeItem("ACTUAL_CONSENT_ID"); 
                this.errMessage = "Você atingiu o limite de 05 minutos sem executar nenhuma ação.";
                this.showErrMessage = true;
                this.searchDisabled = true;
                this.loadingIcon = "";
            }, MAX_TIMEOUT);
        });

        function timeoutPromise() {
          return new Promise(resolve => {
            timeout = setTimeout(() => {
              resolve(FetchStates.TecbanTimeout);
            }, TECBAN_TIMEOUT);
          });
        }

        let consent = await Promise.race([
            timeoutPromise(),
            getConsentById(sessionStorage.getItem("ACTUAL_CONSENT_ID"), "detail", token)
        ]);

        if(consent != FetchStates.TecbanTimeout) {
            clearTimeout(timeout);
            if (Object.prototype.hasOwnProperty.call(consent,"getConsentError" )) {
                let consentError = consent.getConsentError;
                if(consentError === ConsultConsentErrors.InvalidToken)
                    this.errMessage = "Usuário não efetuou login. Efetue login e tente novamente.";
                else if(consentError === ConsultConsentErrors.InvalidUrn)
                    this.errMessage = "Dados inválidos. Verifique e tente novamente";
                this.showErrMessage = true;
                this.loadingIcon = "";
            } 
            else {

                let payload = consent.JWE;
                let payerAccountUsed;
                const consentDetailCmpl = consent.consentDetailCmpl ? consent.consentDetailCmpl :  ""
                this.showErrMessage = false;
                this.consentId = consent.data.consentBody.data.consentId;
                this.status = consent.data.consentBody.data.status;
                this.creditorName = consent.data.consentBody.data.creditor.name;
                this.creditorCpfCnpj = this.maskCpfCnpj(consent.data.consentBody.data.creditor.cpfCnpj),
                this.paymentIniciationDate = this.convertUtcDateToString(consent.data.consentBody.data.creationDateTime, 'pt-BR', false);
                this.paymentValue = this.parseCurrency('pt-BR', consent.data.consentBody.data.payment.currency, consent.data.consentBody.data.payment.amount);
                this.paymentType = consent.data.consentBody.data.payment.type;

                if (Object.prototype.hasOwnProperty.call(consent.data.consentBody.data.payment, 'schedule')) {
                    this.paymentDateSchedule = this.convertUtcDateToString(consent.data.consentBody.data.payment.schedule.single.date, 'pt-BR', false);
                } 

                if(!this.isEmpty(payload) && this.status != ConsentStatus.WAITING) {
                    this.approvedBy = payload.name;
                } else {
                    this.approvedBy = consent.consentDetailCmpl.consentPsuName;
                }

                if (consent.data.consentBody.data.businessEntity != null){
                    this.payerCpfCnpj = this.maskCpfCnpj(consent.data.consentBody.data.businessEntity.document.identification);
                }
                else {
                    this.payerCpfCnpj = this.maskCpfCnpj(consent.data.consentBody.data.loggedUser.document.identification);
                }
                
                this.loadingIcon = "";
                this.detailConsentStyle = "font-size:14px; visibility:visible";

                this.initiatorName = (!this.isEmpty(consent.ssaDetail) ? consent.ssaDetail.org_name : null);

                if(this.status != ConsentStatus.WAITING)
                    this.approvedDate = this.convertUtcDateToString(consent.data.consentBody.data.statusUpdateDateTime, 'pt-BR', true).replace(' ',' - ');

                // get payer account info
                if (!this.isEmpty(payload) && (!this.isEmpty(consent.data.accountIds) || !this.isEmpty(payload.account_id))){
                    
                    // authorized and rejected
                    if (!this.isEmpty(consent.data.accountIds)){
                        payload.accounts.forEach(element => {
                        if(element.account_id == consent.data.accountIds[0])
                            payerAccountUsed = element;
                        });
                    }
                    // awaiting
                    else{
                        payload.accounts.forEach(element => {
                        if(element.account_id == payload.account_id)
                            payerAccountUsed = element;
                        });
                    }

                    this.payerName = payerAccountUsed.owner;
                    this.payerAccType = this.accountTypes[payerAccountUsed.account_type];
                    this.payerBranch = payerAccountUsed.account_agency;
                    this.payerAccount = payerAccountUsed.account_number;
                } else {
                    this.payerName = consentDetailCmpl.accountDetail.owner;
                    this.payerAccType = this.accountTypes[consentDetailCmpl.accountDetail.accountType];
                    this.payerBranch = consentDetailCmpl.accountDetail.issuer;
                    this.payerAccount = consentDetailCmpl.accountDetail.number;
                }
                
                this.localInstrument = consent.data.consentBody.data.payment.details.localInstrument.toUpperCase();

                if (this.localInstrument == 'MANU'){

                    let creditorAccount = consent.data.consentBody.data.payment.details.creditorAccount;

                    this.creditorBankCode = creditorAccount.ispb;
                    this.creditorAgg = creditorAccount.issuer;
                    this.creditorAccountType = this.accountTypes[creditorAccount.accountType];
                    this.creditorAccount = creditorAccount.number;
                }
                else
                    this.pixKey = consent.data.consentBody.data.payment.details.proxy;
                
                // get bank fee
                if (!this.isEmpty(payload) && !this.isEmpty(payload.bank_fee) && !isNaN(payload.bank_fee)) {
                    this.bank_fee = this.parseCurrency('pt-BR', consent.data.consentBody.data.payment.currency, payload.bank_fee);
                } else {
                    this.bank_fee = this.parseCurrency('pt-BR', consent.data.consentBody.data.payment.currency, consentDetailCmpl.bankFee);
                }

            }
        } 
        else {
            this.errMessage = "Houve falha na comunicação.Tente novamente mais a tarde.";
            this.showErrMessage = true;
            this.loadingIcon = "";
        }
    }
}
</script>

<style scoped>
.container {
    width: 100%;
    height: 100vh;
    background-color: var(--tecban-back-color1);
}

.box {
    background: var(--tecban-default-back-color);
    box-shadow: var(--tecban-default-shadow);
    padding:1em;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
}
.box :active {
    border: none;
}
.box-text 
{
    color: var(--tecban-text-gray2);
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
}
.table-header {
    
/* Title Bold 4 */

font-family: Open Sans;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 24px;
/* identical to box height, or 133% */
background: #ECEBED;
align-items: center;

/* Cinza TecBan */

color: #808080;
border: 1px solid #808080;

}

.stripped {
    background-color:#FCFCFC;
}
.text {
    padding: 1em;
}

.status-waiting {
    color: var(--tecban-card-waiting) !important;
}

.status-consumed {
    color: var(--tecban-card-consumed) !important;
}

.status-authorised {
    color: var(--tecban-card-authorised) !important;
}

.status-expired {
    color: var(    --tecban-card-expired) !important;
}

.status-rejected {
    color: var(    --tecban-card-rejected) !important;
}

.status{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}

</style>          
